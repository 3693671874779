import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  Alert,
  Card,
  Table,
  CardBody,
} from "reactstrap";
import moment from 'moment';
import { useTable } from "react-table";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import { getSurveyDetailsById, updateSurvey } from "../../api/survey";
import { omit, sortBy, values } from 'lodash';
import { useDispatch, useSelector } from "react-redux"
import { getSurveyQuestions, getSurveyResponses, getSurveys, getSurvey } from "store/survey/actions"
import AddOptionsModal from "./AddOptionsModal";

const EditSurvey = () => {
  const [showOptions, setShowOptions] = useState(null);
  const { id: stringId } = useParams();
  const id = parseInt(stringId, 10);

  const [otherParams, setOtherParams] = useState({
    searchColumns: [{ name: "surveyId", value: id }],
    pageSize: 100,
    pageNumber: 0,
    sortBy: "string",
    ascendingSort: true,
  })

  const { surveyQuestions, surveyQuestionsPages, totalQuestions, surveyResponses, surveyResponsePages, totalResponses, survey } = useSelector((state) => ({
    surveyQuestions: state.Surveys.surveyQuestions,
    surveyQuestionsPages: state.Surveys.surveyQuestionsPages,
    totalQuestions: state.Surveys.totalQuestions,
    surveyResponses: state.Surveys.surveyResponses,
    surveyResponsePages: state.Surveys.surveyResponsePages,
    totalResponses: state.Surveys.totalResponses,
    survey: state.Surveys.survey
  }));

  const [data, setData] = useState({
    [moment().unix()]: {
      id: moment().unix(),
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [optionsError, setOptionsError] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    document.title = "Edit Survey | CA Portal";
  }, []);

  useEffect(() => {
    if (surveyQuestions) {
      const initData = {};
      const initForm = {};
      Array.isArray(surveyQuestions) &&
        surveyQuestions.forEach((e) => {
          initData[e.id] = e;
          Object.keys(e).forEach((key) => {
            if (e[key]) {
              initForm[`${e.id}.${key}`] = e[key];
            }
          });
        });
      Object.keys(initData).length && setData(initData);
      // form.setFieldsValue(initForm);
    }
  }, [surveyQuestions]);

  useEffect(() => {
    dispatch(getSurvey(id));
    dispatch(getSurveyQuestions({ page: 0, surveyId: id, size: 20 }));
  }, [id, updateSurvey, dispatch]);

  useEffect(() => {
    if (survey) {
      formik.setValues({
        title: survey.title || "",
        description: survey.description || "",
        type: survey.type || "",
        options: survey.options
          ? survey.options.map(option => option.content)
          : [""],
      });
    }
  }, [survey]);

  const handleAddOption = () => {
    formik.setFieldValue("options", [...formik.values.options, ""]);
  };

  const handleDeleteOption = index => {
    const updatedOptions = [...formik.values.options];
    updatedOptions.splice(index, 1);
    formik.setFieldValue("options", updatedOptions);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      type: "",
      options: [""],
    },
    onSubmit: async (values, { setSubmitting }) => {
      setError(null);
      setOptionsError(null);

      const updatedData = Object.values(data).map((item) => {
        if (String(item.id).includes("-new")) {
          const { id, ...rest } = item;
          return rest;
        }
        return item;
      });

      try {
        const surveyData = {
          ...survey,
          questions: updatedData,
        };
        await updateSurvey(id, surveyData);
        toast("Survey Questions updated successfully!")
      } catch (error) {
        console.error("Error updating survey:", error);
        setError("Failed to update survey. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleInput = event => {
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [formik.values.description]);

  const handleBack = () => {
    navigate("/surveys");
  };

  // Editable cell renderer
  const EditableCell = ({ value: initialValue, row: { original }, column: { id }, updateData }) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      updateData(original.id, id, value); // Update parent state on blur
    };

    // Conditionally render a dropdown for the "type" column
    if (id === "mandatory") {
      return (
        <Input
          type="select"
          value={value}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setValue(selectedValue);
            updateData(original.id, id, selectedValue); // Update immediately on change
          }}
          bsSize="sm"
          style={{ width: "100%" }}
        >
          <option value="true">YES</option>
          <option value="false">NO</option>
        </Input>
      );
    }

    // Conditionally render a dropdown for the "type" column
    if (id === "type") {
      return (
        <Input
          type="select"
          value={value}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setValue(selectedValue);
            updateData(original.id, id, selectedValue); // Update immediately on change
          }}
          bsSize="sm"
          style={{ width: "100%" }}
        >
          <option value="">Select...</option>
          <option value="Open">Open Answer</option>
          <option value="Single">Single Option Answer</option>
          <option value="Multiple">Multiple Option Answer</option>
        </Input>
      );
    }

    return (
      <Input
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        bsSize="sm"
        style={{ width: "100%" }}
      />
    );
  };

  // Function to add a new empty row
  const addRow = () => {
    setData({
      [`${moment().unix()}-new`]: {
        id: `${moment().unix()}-new`,
      },
      ...data,
    });
  };

  // Function to add a new empty row
  const removeRow = (row) => {
    setData(omit(data, [row.original.id]));
  };

  // Default renderer for non-editable cells
  const defaultColumn = {
    Cell: EditableCell,
  };

  const qcolumns = React.useMemo(
    () => [
      {
        Header: "Question",
        accessor: "text",
      },
      {
        Header: "Question Answer Type",
        accessor: "type",
      },
      {
        Header: "Mandatory",
        accessor: "mandatory",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <Button size="sm" color="primary" onClick={() => setShowOptions(row.original)}>
              Edit
            </Button>
            &nbsp;
            <Button size="sm" color="danger" onClick={() => removeRow(row)}>
              Remove
            </Button>
            &nbsp;
            <Button size="sm" color="success" onClick={addRow}>
              Add Row
            </Button>
          </>
        ),
      },
    ],
    [data]
  );

  const updateData = (rowId, columnId, value) => {
    setData((oldData) => {
      return {
        ...oldData, [rowId]: { ...oldData[rowId], [columnId]: value }
      }
    });
  };

  const formattedData = useMemo(
    () =>
      values(data).map((e) => ({
        ...e,
        value: `${e.first_name || ''} ${e.last_name || ''}`,
      })),
    [data]
  );

  const tableInstance = useTable({
    columns: qcolumns,
    data: formattedData || [],
    defaultColumn,
    updateData,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between mb-3">
            <Breadcrumbs title="Surveys" breadcrumbItem="Edit Survey" />
            <Button
              type="button"
              color="primary"
              className="btn"
              onClick={handleBack}
            >
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </div>
          <Form className="form-horizontal" onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="title">Survey Title:</Label>
              <Input
                type="text"
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Survey Description:</Label>
              <Input
                type="textarea"
                id="description"
                name="description"
                innerRef={inputRef}
                value={formik.values.description}
                onChange={e => {
                  formik.handleChange(e);
                  handleInput(e);
                }}
                onBlur={formik.handleBlur}
                style={{ overflow: "hidden", resize: "none" }}
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for="type">Survey Type:</Label>
              <Input
                type="select"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              >
                <option value="">Select Survey Type</option>
                <option value="Open">Open</option>
                <option value="Multiple">Multiple Choice</option>
                <option value="Single">Single Choice</option>
              </Input>
            </FormGroup> */}
            <h2 className="my-3">Survey Questions</h2>

            <Table bordered hover striped {...getTableProps()} className="table-sm">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th key={JSON.stringify(column)} {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell", { updateData })}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {formik.values.type && formik.values.type !== "Open" && (
              <FormGroup>
                {formik.values.options.map((title, index) => (
                  <div key={index} className="mt-3">
                    <Input
                      type="text"
                      value={title}
                      onChange={e =>
                        formik.setFieldValue(`options.${index}`, e.target.value)
                      }
                      onBlur={formik.handleBlur}
                      required
                    />
                    <Button
                      color="danger"
                      onClick={() => handleDeleteOption(index)}
                      className="mt-2"
                    >
                      Delete Option
                    </Button>
                  </div>
                ))}
                <Button
                  color="success"
                  type="button"
                  onClick={handleAddOption}
                  className="mt-3"
                >
                  Add Option
                </Button>
              </FormGroup>
            )}

            <Button
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Update Survey
            </Button>
          </Form>
          {optionsError && (
            <Alert color="danger" className="mt-3">
              {optionsError}
            </Alert>
          )}
          {error && (
            <Alert color="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </CardBody>
      </Card>
      <AddOptionsModal
        questions={formattedData}
        isNew={formattedData.length}
        setVisible={() => setShowOptions(!showOptions)}
        visible={showOptions}
      />
    </div>
  );
};

export default EditSurvey;
