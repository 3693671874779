import {
  GET_SURVEYS, GET_SURVEYS_FAIL, GET_SURVEYS_SUCCESS, GET_SURVEY, GET_SURVEY_FAIL,
  GET_SURVEY_SUCCESS, GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTIONS_SUCCESS, GET_SURVEY_QUESTIONS_FAIL,
  GET_SURVEY_RESPONSES, GET_SURVEY_RESPONSES_SUCCESS,
  GET_SURVEY_RESPONSES_FAIL, CREATE_SURVEY,
  CREATE_SURVEY_QUESTION, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_FAIL,
  CREATE_SURVEY_QUESTION_SUCCESS, CREATE_SURVEY_QUESTION_FAIL,
  GET_QUESTION_OPTIONS_SUCCESS, GET_QUESTION_OPTIONS,
  GET_QUESTION_OPTIONS_FAIL
} from "./actionTypes"

export const setSurvey = (data) => ({
  type: CREATE_SURVEY,
  payload: data
})

export const setSurveySuccess = (data) => ({
  type: CREATE_SURVEY_SUCCESS,
  payload: data
})

export const setSurveyFail = (error) => ({
  type: CREATE_SURVEY_FAIL,
  payload: error
})

export const setSurveyQuestionSuccess = (data) => ({
  type: CREATE_SURVEY_QUESTION_SUCCESS,
  payload: data
})

export const setSurveyQuestionFail = (error) => ({
  type: CREATE_SURVEY_QUESTION_FAIL,
  payload: error
})

export const setSurveyQuestion = (data) => ({
  type: CREATE_SURVEY_QUESTION,
  payload: data
})

export const getSurveys = (data) => ({
  type: GET_SURVEYS,
  payload: data
})

export const getSurveysSuccess = chats => ({
  type: GET_SURVEYS_SUCCESS,
  payload: chats,
})

export const getSurveysFail = error => ({
  type: GET_SURVEYS_FAIL,
  payload: error,
})

export const getSurvey = (id) => ({
  type: GET_SURVEY,
  payload: id
})

export const getSurveySuccess = data => ({
  type: GET_SURVEY_SUCCESS,
  payload: data,
})

export const getSurveyFail = error => ({
  type: GET_SURVEY_FAIL,
  payload: error,
})

export const getSurveyQuestions = (data) => ({
  type: GET_SURVEY_QUESTIONS,
  payload: data
})

export const getSurveyQuestionsSuccess = groups => ({
  type: GET_SURVEY_QUESTIONS_SUCCESS,
  payload: groups,
});

export const getSurveyQuestionsFail = error => ({
  type: GET_SURVEY_QUESTIONS_FAIL,
  payload: error,
})

export const getQuestionOptions = (id) => ({
  type: GET_QUESTION_OPTIONS,
  payload: id
})

export const getQuestionOptionsSuccess = (data) => ({
  type: GET_QUESTION_OPTIONS_SUCCESS,
  payload: data
})

export const getQuestionOptionsFail = (data) => ({
  type: GET_QUESTION_OPTIONS_FAIL,
  payload: data
})

export const getSurveyResponses = (data) => ({
  type: GET_SURVEY_RESPONSES,
  payload: data
})

export const getSurveyResponsesSuccess = contacts => ({
  type: GET_SURVEY_RESPONSES_SUCCESS,
  payload: contacts,
})

export const getSurveyResponsesFail = error => ({
  type: GET_SURVEY_RESPONSES_FAIL,
  payload: error,
})



