import { CREATE_SURVEY_FAIL, CREATE_SURVEY_QUESTION, CREATE_SURVEY_QUESTION_FAIL, CREATE_SURVEY_QUESTION_SUCCESS, CREATE_SURVEY_SUCCESS, GET_QUESTION_OPTIONS_FAIL, GET_QUESTION_OPTIONS_SUCCESS, GET_SURVEY_FAIL, GET_SURVEY_QUESTIONS_FAIL, GET_SURVEY_QUESTIONS_SUCCESS, GET_SURVEY_RESPONSES_SUCCESS, GET_SURVEY_SUCCESS, GET_SURVEYS_FAIL, GET_SURVEYS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  surveys: [],
  survey: {},
  surveyQuestion: {},
  surveyQuestions: [],
  surveyResponses: [],
  questionOptions: [],
  totalSurveys: 0,
  surveyPages: 0,
  totalQuestions: 0,
  surveyQuestionsPages: 0,
  error: {},
  loading: true
}

const Survey = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_SURVEY_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        survey: action.payload,
        loading: false
      }
    case CREATE_SURVEY_FAIL:
      console.log(action.payload);
      return {
        ...state,
        survey: {},
        error: action.payload,
        loading: false
      }
    case GET_QUESTION_OPTIONS_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        questionOptions: action.payload,
        loading: false
      }
    case GET_QUESTION_OPTIONS_FAIL:
      console.log(action.payload);
      return {
        ...state,
        questionOptions: [],
        error: action.payload,
        loading: false
      }
    case CREATE_SURVEY_QUESTION_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        surveyQuestion: action.payload,
        loading: false
      }
    case CREATE_SURVEY_QUESTION_FAIL:
      console.log(action.payload);
      return {
        ...state,
        surveyQuestion: {},
        error: action.payload,
        loading: false
      }
    case GET_SURVEYS_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        surveys: action.payload.data,
        totalSurveys: 0,
        surveyPages: 0,
        loading: false
      }

    case GET_SURVEYS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        surveyQuestions: action.payload.data,
        totalQuestions: action.payload.totalItems,
        surveyQuestionsPages: action.payload.totalPages,
        loading: false
      }

    case GET_SURVEY_QUESTIONS_FAIL:
    case GET_SURVEY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SURVEY_RESPONSES_SUCCESS:
      return {
        ...state,
        surveyResponses: action.payload.data,
        totalResponses: action.payload.totalItems,
        surveyResponsePages: action.payload.totalPages,
        loading: false
      }
    case GET_SURVEY_SUCCESS:
      return {
        ...state,
        survey: action.payload.data,
        loading: false
      }
    default:
      return state
  }
}

export default Survey;
