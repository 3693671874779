import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    Col,
    Row,
    FormFeedback,
    FormGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { getQuestionOptions } from "store/survey/actions"
import { updateQuestion } from 'api/survey';

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const AddOptionsModal = ({
    visible,
    isNew,
    setVisible,
}) => {
    const [file, setSelectedFile] = useState(null);
    const dispatch = useDispatch();

    const { questionOptions } = useSelector((state) => ({
        questionOptions: state.Surveys.questionOptions,
    }));

    const [optionValues, setOptionValues] = useState([{
        content: "", id: '-new'
    }]);

    useEffect(() => {
        if (questionOptions.data) {
            setOptionValues(options => {
                return [...questionOptions.data?.map((o, i) => ({ content: o.content, id: o.id + '-new' })), { content: "", id: '-new' }];
            });
        }
    }, [questionOptions]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            text: visible?.text || '',
            type: visible?.type || '',

        },
        validationSchema: Yup.object({
            text: Yup.string().required('Please Enter Question title'),
            type: Yup.string().required('Please Enter Question type'),
        }),
        onSubmit: async ({ ...values }, { resetForm }) => {
            try {
                const updatedData = Object.values(optionValues).map((item) => {
                    const id = String(item.id).replace("-new", "");
                    return {
                        ...item,
                        id: (id === 'undefined' || !id) ? null : id, // Remove '-new' from 'id'
                    };
                });
                const data = {
                    ...values,
                    ...visible,
                    questionId: visible.id,
                    options: updatedData
                };
                console.log(optionValues);
                await updateQuestion(visible.id, data).then((res) => {
                    toast("Survey Question updated successfully!")
                });
            } catch (error) {
                console.log(error);
            }

        },
    });

    const handleAcceptedFiles = (files) => {
        files.forEach((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            }),
        );
        setSelectedFile(files);
    };

    const onFileChange = (e) => {
        setSelectedFile(e.currentTarget.files[0]);
        validation.handleChange(e);
    };

    useEffect(() => {
        console.log(visible);
        if (visible) {
            dispatch(getQuestionOptions(visible.id));
        }
    }, [visible, dispatch]);

    const handleAddOption = () => {
        setOptionValues(
            [...optionValues, { content: '' }]
        );
    };

    const handleDeleteOption = (id, index) => {
        const updatedOptions = [...optionValues];
        updatedOptions.splice(index, 1);
        setOptionValues([
            ...updatedOptions,
        ]);
    };

    const handleOptionTitleChange = (id, index, value) => {
        const updatedOptions = [...optionValues];
        console.log(updatedOptions)
        updatedOptions[index] = { id, content: value };
        setOptionValues(updatedOptions);
    };

    return (
        <Modal
            size="lg"
            isOpen={visible}
            toggle={setVisible}
        // className={className}
        // backdrop={backdrop}
        // keyboard={keyboard}
        >
            <ModalHeader toggle={setVisible}>
                {isNew ? 'Add Question Options' : 'Edit Qustion Options'}
            </ModalHeader>
            <ModalBody>
                <Form
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >

                    <Row>
                        <Col>
                            <h5>Question </h5>
                            <h3>{visible?.text || ''} </h3>
                        </Col>
                        <Col>
                            <h5>Question Answer type </h5>
                            <h3>{visible?.type || ''} </h3>
                        </Col>
                    </Row>

                    <h2 className='mt-4'>Question Options </h2>
                    <FormGroup>
                        <Label for="title">Question Title:</Label>
                        <Input
                            type="text"
                            id="text"
                            name="text"
                            value={validation.values.text}
                            onChange={validation.handleChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="type">Survey Type:</Label>
                        <Input
                            type="select"
                            id="type"
                            name="type"
                            value={validation.values.type}
                            onChange={validation.handleChange}
                            required
                        >
                            <option value="">Select Question Type</option>
                            <option value="Open">Open</option>
                            <option value="Multiple">Multiple Choice</option>
                            <option value="Single">Single Choice</option>
                        </Input>
                    </FormGroup>
                    {visible?.type && visible?.type !== "Open" && (
                        <FormGroup>
                            {optionValues?.map((o, index) => (
                                <Row key={index} className="mt-3 justify-items-center" >
                                    <Col md={10}>
                                        <Input
                                            type="text"
                                            name={`${index}-content`}
                                            value={o.content}
                                            onChange={e => handleOptionTitleChange(o.id, index, e.target.value)}
                                            required
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Button
                                            color="danger"
                                            size='sm'
                                            onClick={() => handleDeleteOption(o.id, index)}
                                            className="mt-0"
                                        >
                                            Delete Option
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            <Button
                                color="success"
                                type="button"
                                onClick={handleAddOption}
                                className="mt-3"
                            >
                                Add Option
                            </Button>
                        </FormGroup>
                    )}
                    {/* {error && <FormFeedback className="d-block">{error}</FormFeedback>} */}
                    {/* <div className="text-center mb-3">
                        <Button color="success" type="submit">
                            
                        </Button>
                    </div> */}
                    <FormGroup className="mt-4">
                        <Button type="submit" color="primary" block>
                            Save Survey Question
                        </Button>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={setVisible}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

AddOptionsModal.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
};

export default AddOptionsModal;
