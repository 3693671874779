import React, { useMemo, useState, useEffect } from "react"
import {
  Row,
  Container,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { getIncidents } from "api/incidents"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { exportExcel } from "api/crud"
import { IncidentReportSummary } from "api/reports"
import DatePicker from "react-datepicker"
import { useRole } from "hooks/useRole"

const IncidentIndex = () => {
  const navigate = useNavigate()
  const [modal, setModal] = useState(false)

  const { isAdmin, isSuperAdmin, isAnalyst } = useRole()

  React.useEffect(() => {
    document.title = "Incidents | CA Portal"
  }, [])

  const [incidentReportData, setIncidentReportData] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [incidentsData, setIncidentsData] = useState([])
  const [pages, setPages] = useState(0)

  const [otherParams, setOtherParams] = useState({
    pageSize: 100,
    pageNumber: 7,
    searchColumns: [],
    sortBy: "id",
    ascendingSort: true,
  })

  useEffect(() => {
    const currentDate = new Date()
    const firstDayOfYear = new Date(currentDate.getFullYear(), 1)
    const lastDayOfYear = new Date(currentDate.getFullYear() + 1, 0)
    const initialStartDate = formatDate(firstDayOfYear)
    const initialEndDate = formatDate(lastDayOfYear)

    setStartDate(firstDayOfYear)
    setEndDate(lastDayOfYear)

    fetchIncidentData({
      fromDate: initialStartDate,
      toDate: initialEndDate,
      ...otherParams,
    })
    fetchReportData({
      fromDate: initialStartDate,
      toDate: initialEndDate,
      ...otherParams,
    })
  }, [])

  const fetchIncidentData = async params => {
    try {
      const incidentRes = await getIncidents(params)

      setIncidentsData(incidentRes?.data)
      setPages(incidentRes?.totalPages)
      console.log(incidentRes.totalPages)
    } catch (error) {
      console.error("Error fetching incidents data:", error)
    }
  }

  const fetchReportData = async params => {
    try {
      const reportRes = await IncidentReportSummary(params)

      setIncidentReportData(reportRes.data)
      console.log(reportRes.data)
    } catch (error) {
      console.error("Error fetching report data:", error)
    }
  }

  const handleApply = () => {
    const params = {
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
      ...otherParams,
    }
    fetchIncidentData(params)
    fetchReportData(params)
  }

  const formatDate = date => {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    if (!(date instanceof Date) || isNaN(date.getDate())) {
      return ""
    }
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const handleStartDateChange = date => {
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    setEndDate(date)
  }

  const columns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
      },
      {
        Header: "REPORT TYPE",
        accessor: "reportType",
      },

      {
        Header: "Reporter Name",
        accessor: row => {
          const firstName = row.reporter?.firstName || ""
          const lastName = row.reporter?.lastName || ""
          return firstName && lastName ? `${firstName} ${lastName}` : ""
        },
        Cell: ({ value }) => value,
      },

      {
        Header: "Reporter Email",
        accessor: row => row.reporter?.email || "",
        Cell: ({ value }) => value,
      },

      {
        Header: "STATUS",
        accessor: "status",
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
      },
      {
        Header: "DATE REPORTED",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleString(undefined, {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
          return formattedDate
        },
      },
      {
        Header: "TICKET NUMBER",
        accessor: "ticket.ticketNumber",
      },
    ],
    []
  )

  const handleViewDetails = record => {
    navigate(`/incidents/${record.original.id}`)
  }

  

  const handleExport = async () => {
    try {
      const params = {
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
        ...otherParams,
      }

      await exportExcel("incidents", params)
    } catch (error) {
      console.error("Error exporting incidents:", error)
    }
  }

  const totalIncidents = incidentReportData
    ? incidentReportData.reduce((accumulator, monthData) => {
        return accumulator + monthData.totalIncidents
      }, 0)
    : 0

  const totalNewIncidents = incidentReportData
    ? incidentReportData.reduce((accumulator, monthData) => {
        return accumulator + monthData.totalNewIncidents
      }, 0)
    : 0

  const totalPendingIncidents = incidentReportData
    ? incidentReportData.reduce((accumulator, monthData) => {
        return accumulator + monthData.totalPendingIncidents
      }, 0)
    : 0

  const totalClosedIncidents = incidentReportData
    ? incidentReportData.reduce((accumulator, monthData) => {
        return accumulator + monthData.totalClosedIncidents
      }, 0)
    : 0

  const totalIdentifiedIncidents = incidentReportData
    ? incidentReportData.reduce((accumulator, monthData) => {
        return accumulator + monthData.totalIdentifiedIncidents
      }, 0)
    : 0

  const totalAnonymousIncidents = incidentReportData
    ? incidentReportData.reduce((accumulator, monthData) => {
        return accumulator + monthData.totalAnonymousIncidents
      }, 0)
    : 0

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Incidents" breadcrumbItem="Incident Reports" />
          <Row className="mb-3">
            <Col md={3}>
              <div className="d-flex flex-column align-items-center">
                <label className="form-label">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  className="form-control"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex flex-column align-items-center">
                <label className="form-label">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  className="form-control"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex flex-column align-items-center mt-3">
                <Button color="primary" onClick={handleApply}>
                  Apply Filter
                </Button>
              </div>
            </Col>
            {(isAdmin || isSuperAdmin || isAnalyst) && (
              <Col md={3}>
                <div className="d-flex flex-column align-items-center mt-3">
                  <Button color="success" onClick={handleExport}>
                    Export Incidents
                  </Button>
                </div>
              </Col>
            )}
          </Row>

          <Row className="mb-4">
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="card-title">Total Incidents</h5>
                  <p>{totalIncidents}</p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="card-title">Total New Incidents</h5>
                  <p>{totalNewIncidents}</p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="card-title">Total Pending Incidents</h5>
                  <p>{totalPendingIncidents}</p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="card-title">Total Closed Incidents</h5>
                  <p>{totalClosedIncidents}</p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="card-title">Total Identified Incidents</h5>
                  <p>{totalIdentifiedIncidents}</p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="card-title">Total Anonymous Incidents</h5>
                  <p>{totalAnonymousIncidents}</p>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <TableContainer
            showView={isAdmin || isSuperAdmin}
            columns={columns}
            data={incidentsData || []}
            isGlobalFilter={true}
            isAddOptions={false}
            handleView={handleViewDetails}
            customPageSize={10}
            isPagination={true}
            pages={pages}
            iscustomPageSizeOptions={true}
            tableClass="align-middle table-nowrap table-check table"
            theadClass="table-light"
            paginationDiv="col-12"
            pagination="justify-content-right pagination pagination-rounded"
            // paginationData={pagination}
            // onChangePagination={onChangePagination}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IncidentIndex
