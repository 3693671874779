/* SURVEYS */
export const GET_SURVEYS = "GET_SURVEYS"
export const GET_SURVEYS_SUCCESS = "GET_SURVEYS_SUCCESS"
export const GET_SURVEYS_FAIL = "GET_SURVEYS_FAIL"

/* SURVEYS */
export const CREATE_SURVEY = "CREATE_SURVEY"
export const CREATE_SURVEY_SUCCESS  = "CREATE_SURVEY_SUCCESS"
export const CREATE_SURVEY_FAIL  = "CREATE_SURVEY_FAIL"
export const CREATE_SURVEY_QUESTION = "CREATE_SURVEY_QUESTION"
export const CREATE_SURVEY_QUESTION_SUCCESS  = "CREATE_SURVEY_QUESTION_SUCCESS"
export const CREATE_SURVEY_QUESTION_FAIL  = "CREATE_SURVEY_QUESTION_FAIL"
export const GET_SURVEY = "GET_SURVEY"
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS"
export const GET_SURVEY_FAIL = "GET_SURVEY_FAIL"

/* SURVEYS QUESTIONS */
export const GET_SURVEY_QUESTIONS = "GET_SURVEY_QUESTIONS"
export const GET_SURVEY_QUESTIONS_SUCCESS = "GET_SURVEY_QUESTIONS_SUCCESS"
export const GET_SURVEY_QUESTIONS_FAIL = "GET_SURVEY_QUESTIONS_FAIL"

/* SURVEYS QUESTION OPTIONS */
export const GET_QUESTION_OPTIONS = "GET_QUESTION_OPTIONS"
export const GET_QUESTION_OPTIONS_SUCCESS = "GET_QUESTION_OPTIONS_SUCCESS"
export const GET_QUESTION_OPTIONS_FAIL = "GET_QUESTION_OPTIONS_FAIL"


/* SURVEYS RESPONSES */
export const GET_SURVEY_RESPONSES = "GET_SURVEY_RESPONSES"
export const GET_SURVEY_RESPONSES_SUCCESS = "GET_SURVEY_RESPONSES_SUCCESS"
export const GET_SURVEY_RESPONSES_FAIL = "GET_SURVEY_RESPONSES_FAIL"

