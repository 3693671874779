import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Form,
  FormGroup,
  Label,
  CardTitle,
  Button,
  Card,
  CardBody,
  Alert,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import TableContainer from "components/Common/TableContainer"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { createSurveyQuestion, createSurveyTitle } from "../../api/survey";
import { useDispatch, useSelector } from "react-redux"
import { getSurveyResponses, getSurveys, getSurvey, setSurvey, getSurveyQuestions, setSurveyQuestion, setSurveyQuestionSuccess, setSurveySuccess } from "store/survey/actions";


const Survey = () => {
  const dispatch = useDispatch()
  document.title = "Create Surveys";

  const [surveyCreated, setSurveyCreated] = useState(false);
  const [surveyData, setSurveyData] = useState(null);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);

  const { surveyQuestion, surveyQuestions, survey, surveyQuestionsPages, totalQuestions, } = useSelector((state) => ({
    surveyQuestionsPages: state.Surveys.surveyQuestionsPages,
    totalQuestions: state.Surveys.totalQuestions,
    surveyQuestions: state.Surveys.surveyQuestions,
    surveyQuestion: state.Surveys.surveyQuestion,
    survey: state.Surveys.survey,
  }));

  const navigate = useNavigate();

  useEffect(() => {
    if (surveyCreated) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [surveyCreated]);

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    type: "",
  });

  const [questionFormValues, setQuestionFormValues] = useState({
    text: "",
    type: "",
    options: [""],
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const surveyData = {
        title: formValues.title,
        description: formValues.description,
      };

      const topicResponse = await createSurveyTitle(surveyData);
      dispatch(setSurveySuccess(topicResponse.data));

      setSurveyCreated(true);
      setSurveyData({
        ...topicResponse.data
      });

      setFormValues({
        title: "",
        description: "",
        type: "",
        options: [""],
      });
      setError(null);
    } catch (error) {
      console.error("Error creating survey:", error);
      setError("Failed to create survey. Please try again.");
    }
  };

  useEffect(() => {
    if (survey?.id) {
      dispatch(getSurveyQuestions({ page: 0, surveyId: survey.id, size: 20 }));
    }
  }, [dispatch, survey])

  const handleQuestionSubmit = async (event) => {
    event.preventDefault();

    if (questionFormValues.type !== "Open" && questionFormValues.options.length < 2) {
      setError("At least two options are required");
      return;
    }

    try {
      const questionData = {
        text: questionFormValues.text,
        type: questionFormValues.type,
        surveyId: surveyData.id,
        options: questionFormValues.type === "Open" ? [] : questionFormValues.options.map(title => ({ Content: title, surveyId: surveyData.id })),
      };

      const topicResponse = await createSurveyQuestion(questionData);
      dispatch(setSurveyQuestionSuccess(topicResponse.data))

      setError(null);
      // navigate('/survey/' + surveyData.id)
    } catch (error) {
      console.error("Error creating survey:", error);
      setError("Failed to create survey. Please try again.");
    }
  };

  const handleAddOption = () => {
    setQuestionFormValues({
      ...questionFormValues,
      options: [...questionFormValues.options, ""],
    });
  };

  const handleDeleteOption = index => {
    const updatedOptions = [...questionFormValues.options];
    updatedOptions.splice(index, 1);
    setQuestionFormValues({
      ...questionFormValues,
      options: updatedOptions,
    });
  };

  const handleOptionTitleChange = (index, value) => {
    const updatedOptions = [...questionFormValues.options];
    updatedOptions[index] = value;
    setQuestionFormValues({
      ...questionFormValues,
      options: updatedOptions,
    });
  };

  const handleInput = event => {
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [formValues.description]);

  const handleBack = () => {
    navigate("/surveys");
  };

  const handleCreateAnother = () => {
    window.location.reload();
  };

  const qcolumns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
      },
      {
        Header: "QUESTION",
        accessor: "text",
      },
      {
        Header: "TYPE",
        accessor: "type"
      },
      // {
      //   Header: "ANSWER/OPTION(S)",
      //   accessor: row => {
      //     if (row.text) return row.text
      //     if (row.optionIds) {
      //       return row.optionIds
      //         .map(optionId => optionIdToNameMap[optionId] || optionId)
      //         .join(", ")
      //     }
      //     return ""
      //   },
      //   Cell: ({ value }) => <p>{value}</p>,
      // },
    ],
    []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between mb-3">
              <Breadcrumbs title="Surveys" breadcrumbItem="Create Survey" />
              <Button type="button" color="primary" onClick={handleBack}>
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </div>
            {!surveyCreated ? (
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="title">Survey Title:</Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    value={formValues.title}
                    onChange={e => setFormValues({ ...formValues, title: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">Survey Description:</Label>
                  <Input
                    type="textarea"
                    id="description"
                    name="description"
                    innerRef={inputRef}
                    value={formValues.description}
                    onChange={e => {
                      setFormValues({ ...formValues, description: e.target.value });
                      handleInput(e);
                    }}
                    style={{ overflow: "hidden", resize: "none" }}
                    required
                  />
                </FormGroup>

                {/* Create survey button */}
                <div className="text-center mt-3">
                  <Button
                    color="primary"
                    type="submit"
                  >
                    Create Survey
                  </Button>
                </div>
              </Form>
            ) : (
              <div className="mt-3 mb-3">
                {error && <Alert color="danger">{error}</Alert>}
                <p>
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                      marginRight: "8px",
                    }}
                  >
                    Title:
                  </span>
                  {surveyData?.title}
                </p>
                <p>
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                      marginRight: "8px",
                    }}
                  >
                    Description:
                  </span>
                  {surveyData?.description}
                </p>
                <h2>Add Survey Questions</h2>
                <Form className="form-horizontal" onSubmit={handleQuestionSubmit}>
                  <FormGroup>
                    <Label for="title">Question Title:</Label>
                    <Input
                      type="text"
                      id="text"
                      name="text"
                      value={questionFormValues.text}
                      onChange={e => setQuestionFormValues({ ...formValues, text: e.target.value })}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="type">Survey Type:</Label>
                    <Input
                      type="select"
                      id="type"
                      name="type"
                      value={questionFormValues.type}
                      onChange={e => setQuestionFormValues({ ...questionFormValues, type: e.target.value })}
                      required
                    >
                      <option value="">Select Question Type</option>
                      <option value="Open">Open</option>
                      <option value="Multiple">Multiple Choice</option>
                      <option value="Single">Single Choice</option>
                    </Input>
                  </FormGroup>
                  {questionFormValues.type && questionFormValues.type !== "Open" && (
                    <FormGroup>
                      {questionFormValues.options.map((title, index) => (
                        <Row key={index} className="mt-3 justify-items-center" >
                          <Col md={10}>
                            <Input
                              type="text"
                              value={title}
                              onChange={e => handleOptionTitleChange(index, e.target.value)}
                              required
                            />
                          </Col>
                          <Col md={2}>
                            <Button
                              color="danger"
                              onClick={() => handleDeleteOption(index)}
                              className="mt-0"
                            >
                              Delete Option
                            </Button>
                          </Col>

                        </Row>
                      ))}
                      <Button
                        color="success"
                        type="button"
                        onClick={handleAddOption}
                        className="mt-3"
                      >
                        Add Option
                      </Button>
                    </FormGroup>
                  )}
                  {error && <FormFeedback className="d-block">{error}</FormFeedback>}
                  <div className="text-center mb-3">
                    <Button color="success" type="submit">
                      Save Survey Question
                    </Button>
                  </div>
                </Form>
                {surveyData?.options && surveyData.options.length > 0 && (
                  <>
                    <p
                      style={{
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        marginBottom: "8px",
                      }}
                    >
                      Options:
                    </p>
                    <ul>
                      {surveyData.options.map((option, index) => (
                        <li key={index}>{option.content}</li>
                      ))}
                    </ul>
                  </>
                )}
                {/* <div className="text-center mb-3">
                  <Button color="success" onClick={handleCreateAnother}>
                    Create Another Survey
                  </Button>
                </div> */}
              </div>
            )}
          </CardBody>
        </Card>
        {
          surveyQuestions.length > 0 && <Card>
            <CardBody>
              <CardTitle tag="h5">Survey Questions</CardTitle>
              <TableContainer
                columns={qcolumns}
                data={surveyQuestions || []}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                isPagination={true}
                iscustomPageSizeOptions={true}
                tableClass="align-middle table-nowrap table-check table"
                theadClass="table-light"
                paginationDiv="col-12"
                pagination="justify-content-left pagination pagination-rounded"
              />
            </CardBody>
          </Card>
        }

      </div>
    </React.Fragment>
  );
};

export default Survey;
