import { get, post, put, del } from './utils';


export async function createQuestions(data) {
    return post(`/questions`, data);
}

export async function createSurveyTitle(data) {
    return post(`/survey`, data);
}


export async function createSurveyQuestion(data) {
    return post(`/survey-question`, data);
}


export async function createSurveyOption(data) {
    return post(`/survey-option`, data);
}

export async function deleteSurveyOption(optionId) {
    return del(`/survey-option/${optionId}`);
}

export async function getSurveyApi(id) {
    return get(`/survey/${id}`)
}

export async function getQuestionOptionsApi(id) {
    console.log(id);
    return get(`/survey-option/?questionId=${id}`)
}

export async function deleteSurvey(id, data) {
    return del(`/survey/${id}`, data)
}

export async function getSurveysApi(page, size) {
    return get(`/survey/?page=${page}&size=${size}`)
}

export async function getSurveyResponsesApi({ page, surveyId, size }) {
    return get(`/survey-response?page=${page}&surveyId=${surveyId}&size=${size}`)
}

export async function getSurveyQuestionsApi({ page, surveyId, size }) {
    return get(`/survey-question?page=${page}&surveyId=${surveyId}&size=${size}`)
}

export async function getSurveyOptions({ page, surveyId, size }) {
    return get(`/survey-option?page=${page}&surveyId=${surveyId}&size=${size}`)
}

export async function getSurveys({ pageSize, pageNumber, fromDate, toDate }) {
    return get(`/survey?page=${pageNumber}&size=${pageSize}&fromDate=${fromDate}&toDate=${toDate}`);
}


export async function getTopics() {
    return get(`/forum/topics/recent`)
}

export async function getTopicSlug(slug) {
    return get(`/forum/topics/${slug}`)
}

export async function getSurveyDetailsById(id) {
    return get(`/survey/${id}`)
}

export async function updateSurvey(id, data) {
    return put(`/survey/${id}`, data)
}


export async function updateQuestion(id, data) {
    return put(`/survey-question/${id}`, data)
}

