import React, { useState, useEffect, useMemo } from "react"
import { Container, Button, Card, CardBody, Col, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useNavigate } from "react-router-dom"
import { getSurveys, deleteSurvey } from "api/survey"
import { SurveyReportSummary } from "api/reports"
import { exportExcel } from "api/crud"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useRole } from "hooks/useRole"
import SurveyChart from "./SurveyChart"
import SurveyDonutChart from "./SurveyDonutChart"

const SurveyList = () => {
  React.useEffect(() => {
    document.title = "Surveys | CA Portal"
  }, [])

  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole()

  const navigate = useNavigate()

  const [surveysData, setSurveysData] = useState([])
  const [surveyReportData, setSurveyReportData] = useState([])
  const [pages, setPages] = useState(0)

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [otherParams, setOtherParams] = useState({
    searchColumns: [],
    pageSize: 100,
    pageNumber: 0,
    sortBy: "string",
    ascendingSort: true,
  })

  useEffect(() => {
    const currentDate = new Date()
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1)
    const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31)
    const initialStartDate = formatDate(firstDayOfYear)
    const initialEndDate = formatDate(lastDayOfYear)

    setStartDate(firstDayOfYear)
    setEndDate(lastDayOfYear)

    fetchSurveyData({
      fromDate: initialStartDate,
      toDate: initialEndDate,
      ...otherParams,
    })
    fetchReportData({
      fromDate: initialStartDate,
      toDate: initialEndDate,
      ...otherParams,
    })
  }, [])

  const fetchSurveyData = async params => {
    try {
      const surveyRes = await getSurveys(params)
      setSurveysData(surveyRes?.data)
      console.log(surveyRes?.data)
    } catch (error) {
      console.error("Error fetching surveys data:", error)
    }
  }

  const fetchReportData = async params => {
    try {
      const reportRes = await SurveyReportSummary(params)
      setSurveyReportData(reportRes?.data)
      console.log(reportRes?.data)
    } catch (error) {
      console.error("Error fetching report data:", error)
    }
  }

  const handleApply = () => {
    const params = {
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
      ...otherParams,
    }
    fetchSurveyData(params)
    fetchReportData(params)
  }

  const formatDate = date => {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    if (!(date instanceof Date) || isNaN(date.getDate())) {
      return ""
    }
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const handleStartDateChange = date => {
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    setEndDate(date)
  }

  const columns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      // {
      //   Header: "Survey Type",
      //   accessor: "type",
      // },
      {
        Header: "STATUS",
        accessor: "status",
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleString(undefined, {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
          return formattedDate
        },
      },
    ],
    []
  )

  const handleViewDetails = record => {
    navigate(`/survey/${record.original.id}`)
  }

  const handleCreate = () => {
    navigate("/survey")
  }

  const onEdit = record => {
    navigate(`${"/editsurvey"}/${record.original.id}/edit`)
    console.log(record)
  }

  const handleDelete = async id => {
    try {
      await deleteSurvey(id)
      fetchSurveyData({
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
        ...otherParams,
      })
    } catch (error) {
      console.error("Error deleting survey:", error)
      alert("Error deleting survey. Please try again")
    }
  }

  const handleExport = async () => {
    try {
      const params = {
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
        ...otherParams,
      }

      await exportExcel("survey", params)
    } catch (error) {
      console.error("Error exporting surveys:", error)
      alert("Error exporting surveys. Please try again")
    }
  }


  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Surveys" breadcrumbItem="View Surveys" />
        <Row className="mb-4">
          <Col md={3}>
            <div className="col">
              <label className="form-label">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                className="form-control"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="col">
              <label className="form-label">End Date</label>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                className="form-control"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="col d-flex align-items-center justify-content-center mt-3">
              <Button color="primary" onClick={handleApply}>
                Apply Filter
              </Button>
            </div>
          </Col>
          {(isAdmin || isSuperAdmin || isAnalyst) && (

          <Col md={3}>
            <div className="col d-flex align-items-center justify-content-center mt-3">
              <Button color="success" onClick={handleExport}>
                Export Surveys
              </Button>
            </div>
          </Col>
          )}
        </Row>
 

        {(isAdmin || isSuperAdmin || isAnalyst) && (
          <Row className="mb-4">
            <Col md={6}>
              <Card>
                <CardBody>
                  {surveyReportData && (
                    <SurveyChart
                      dataColors='["--bs-success"]'
                      data={surveyReportData}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  {surveyReportData && (
                    <SurveyDonutChart
                      dataColors='["--bs-warning","--bs-secondary", "--bs-primary", "--bs-danger", "--bs-success"]'
                      data={surveyReportData}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <TableContainer
          showView
          hasCreate={isAdmin || isSuperAdmin || isEditor}
          showEdit={isAdmin || isSuperAdmin || isEditor}
          showDelete={isAdmin || isSuperAdmin}
          columns={columns}
          data={surveysData || []}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          isPagination={true}
          iscustomPageSizeOptions={true}
          addText="Create New Survey"
          tableClass="align-middle table-nowrap table-check table"
          theadClass="table-light"
          paginationDiv="col-12"
          pagination="justify-content-left pagination pagination-rounded"
          handleEdit={onEdit}
          handleView={handleViewDetails}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
          fetchData={handleApply}
        />
      </Container>
    </div>
  )
}

export default SurveyList
