import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { GET_SURVEY_QUESTIONS, GET_SURVEY, GET_SURVEY_RESPONSES, GET_SURVEYS, CREATE_SURVEY, CREATE_SURVEY_QUESTION, GET_QUESTION_OPTIONS } from "./actionTypes";
import { getQuestionOptionsFail, getQuestionOptionsSuccess, getSurveyFail, getSurveyQuestions, getSurveyQuestionsFail, getSurveyQuestionsSuccess, getSurveyResponsesFail, getSurveyResponsesSuccess, getSurveysFail, getSurveysSuccess, getSurveySuccess, setSurveyFail, setSurveyQuestionFail, setSurveyQuestionSuccess, setSurveySuccess } from "./actions";

//Include Both Helper File with needed methods
// import {
//   getChats,
//   getGroups,
//   getContacts,
//   getMessages,
//   addMessage,
//   deleteMessage,
// } from "../../helpers/fakebackend_helper";
import { toast } from "react-toastify";
import { getSurveyApi, getSurveyResponsesApi, getSurveyQuestionsApi, getQuestionOptionsApi } from "api/survey";

function* onGetSurveys() {
  try {
    const response = yield call(getSurveyApi);
    yield put(getSurveysSuccess(response));
  } catch (error) {
    yield put(getSurveysFail(error));
  }
}

function* onGetSurvey({ payload: id }) {
  try {
    const response = yield call(getSurveyApi, id);
    yield put(getSurveySuccess(response));
  } catch (error) {
    yield put(getSurveyFail(error));
  }
}

function* onGetQuestionOptions({ payload: id }) {
  try {
    const response = yield call(getQuestionOptionsApi, id);
    yield put(getQuestionOptionsSuccess(response));
  } catch (error) {
    yield put(getQuestionOptionsFail(error));
  }
}

function* onGetSurveyQuestions({ payload }) {
  try {
    const response = yield call(getSurveyQuestionsApi, payload);
    yield put(getSurveyQuestionsSuccess(response));
  } catch (error) {
    yield put(getSurveyQuestionsFail(error));
  }
}

function* onGetSurveyResponses({ payload }) {
  try {
    const response = yield call(getSurveyResponsesApi, payload);
    yield put(getSurveyResponsesSuccess(response));
  } catch (error) {
    yield put(getSurveyResponsesFail(error));
  }
}

function* onCreateSurvey(data) {
  try {
    // const response = yield call(getSurveyApi, id);
    yield put(setSurveySuccess(data));
  } catch (error) {
    yield put(setSurveyFail(error));
  }
}

function* onCreateSurveyQuestion(data) {
  try {
    // const response = yield call(getSurveyApi, id);
    yield put(setSurveyQuestionSuccess(data));
  } catch (error) {
    yield put(setSurveyQuestionFail(error));
  }
}

function* onGetMessages({ roomId }) {
  try {
    const response = yield call(getMessages, roomId);
    yield put(getMessagesSuccess(response));
  } catch (error) {
    yield put(getMessagesFail(error));
  }
}

function* onAddMessage({ message }) {
  try {
    const response = yield call(addMessage, message);
    yield put(addMessageSuccess(response));
  } catch (error) {
    yield put(addMessageFail(error));
  }
}

function* OnDeleteMessage({ payload: data }) {
  try {
    const response = yield call(deleteMessage, data)
    yield put(deleteMessageSuccess(response))
    toast.success("Message Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteMessageFail(error))
    toast.error("Message Deleted Failed", { autoClose: 2000 });
  }
}

function* surveySaga() {
  yield takeEvery(GET_SURVEYS, onGetSurveys);
  yield takeEvery(GET_SURVEY_RESPONSES, onGetSurveyResponses);
  yield takeEvery(GET_SURVEY_QUESTIONS, onGetSurveyQuestions);
  yield takeEvery(GET_SURVEY, onGetSurvey);
  yield takeEvery(CREATE_SURVEY, onCreateSurvey);
  yield takeEvery(CREATE_SURVEY_QUESTION, onCreateSurveyQuestion);
  yield takeEvery(GET_QUESTION_OPTIONS, onGetQuestionOptions);
  // yield takeEvery(GET_MESSAGES, onGetMessages);
  // yield takeEvery(POST_ADD_MESSAGE, onAddMessage);
  // yield takeEvery(DELETE_MESSAGE, OnDeleteMessage);

}

export default surveySaga;
