import React, { useState, useEffect, useMemo } from "react"
import { useMutation } from "react-query"
import { updateSurvey } from "api/survey"
import { SurveyReportSummary } from "api/reports"
import { useParams, useNavigate } from "react-router-dom"
import {
  CardText,
  Card,
  CardBody,
  Button,
  CardTitle,
  Row,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { useRole } from "hooks/useRole"
import { useDispatch, useSelector } from "react-redux"
import { getSurveyQuestions, getSurveyResponses, getSurveys, getSurvey } from "store/survey/actions"

const SurveyDetails = () => {
  const [activeTab, setActiveTab] = useState('1');

  const dispatch = useDispatch();
  document.title = "Survey | Details"

  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole()

  const { id } = useParams()
  const navigate = useNavigate()

  const [settingsMenu, setSettingsMenu] = useState(false)
  const [surveyData, setSurveyData] = useState({})
  const [unformattedSurveyData, setUnformattedSurveyData] = useState({})
  const [surveyOptionData, setSurveyOptionData] = useState([])
  const [surveyResponseData, setSurveyResponseData] = useState([])
  const [surveyReportData, setSurveyReportData] = useState([])
  const [openSurveyRespondents, setOpenSurveyRespondents] = useState(0)
  const [optionCounts, setOptionCounts] = useState({})
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [optionIdToNameMap, setOptionIdToNameMap] = useState({})
  const [otherParams, setOtherParams] = useState({
    searchColumns: [{ name: "surveyId", value: id }],
    pageSize: 100,
    pageNumber: 0,
    sortBy: "string",
    ascendingSort: true,
  })

  useEffect(() => {
    const currentDate = new Date()
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1)
    const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31)
    const initialStartDate = formatDate(firstDayOfYear)
    const initialEndDate = formatDate(lastDayOfYear)

    setStartDate(firstDayOfYear)
    setEndDate(lastDayOfYear)

    // fetchData({
    //   fromDate: initialStartDate,
    //   toDate: initialEndDate,
    //   ...otherParams,
    // })
    // dispatch(getSurveys({ fromDate, toDate, ...otherParams }))
  }, [otherParams])

  const fetchData = async params => {
    try {
      const reportRes = await SurveyReportSummary(params)
      console.log("API Response:", reportRes.data)

      setSurveyReportData(reportRes.data[0]?.results || [])

      let totalOpenSurveyRespondents = 0
      let counts = {}

      reportRes.data.forEach(report => {
        if (report.results.length > 0) {
          report.results.forEach(result => {
            if (result.surveyType === "Open" && result.count !== undefined) {
              totalOpenSurveyRespondents += result.count
            } else if (
              result.surveyType === "Single" ||
              result.surveyType === "Multiple"
            ) {
              if (!counts[result.optionTitle]) {
                counts[result.optionTitle] = 0
              }
              counts[result.optionTitle] += result.count
            }
          })
        }
      })

      console.log("Total Open Survey Respondents:", totalOpenSurveyRespondents)
      console.log("Option Counts:", counts)

      setOpenSurveyRespondents(totalOpenSurveyRespondents)
      setOptionCounts(counts)
    } catch (error) {
      console.error("Error fetching report data:", error)
    }
  }

  const { surveyQuestions, surveyQuestionsPages, totalQuestions, surveyResponses, surveyResponsePages, totalResponses, survey } = useSelector((state) => ({
    surveyQuestions: state.Surveys.surveyQuestions,
    surveyQuestionsPages: state.Surveys.surveyQuestionsPages,
    totalQuestions: state.Surveys.totalQuestions,
    surveyResponses: state.Surveys.surveyResponses,
    surveyResponsePages: state.Surveys.surveyResponsePages,
    totalResponses: state.Surveys.totalResponses,
    survey: state.Surveys.survey,
  }));

  // console.log(surveyQuestions, surveyQuestionsPages, totalQuestions);
  // console.log(surveyResponses, surveyResponsePages, totalResponses);
  // console.log(survey);

  useEffect(() => {
    const fetchSurveyDetails = async () => {
      try {
        const response = await getSurvey(id)
        if (response.status === 200) {
          setUnformattedSurveyData(response?.data)
          const formattedData = formatDates(response?.data)
          setSurveyData(formattedData)
          setSurveyOptionData(response?.data?.options)

          const optionIdToName = {}
          response?.data?.options.forEach(option => {
            optionIdToName[option.id] = option.content
          })
          setOptionIdToNameMap(optionIdToName)
        }
      } catch (error) {
        console.error("Error fetching survey details:", error)
      }
    }

    // fetchSurveyDetails()
    dispatch(getSurvey(id));
    dispatch(getSurveyResponses({ page: 0, surveyId: id, size: 20 }));
    dispatch(getSurveyQuestions({ page: 0, surveyId: id, size: 20 }));

  }, [id]);

  const formatDate = date => {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    if (!(date instanceof Date) || isNaN(date.getDate())) {
      return ""
    }
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const formatDates = data => {
    const formattedData = { ...data }
    formattedData.createdAt = formatDate(data.createdAt)
    return formattedData
  }

  // const { mutate: mutateSurveyResponses } = useMutation(
  //   () => getSurveyResponse(otherParams.pageNumber, id, otherParams.pageSize),
  //   {
  //     onSuccess: res => {
  //       setSurveyResponseData(res.data)
  //     },
  //     onSettled: () => { },
  //   }
  // )

  // useEffect(() => {
  // mutateSurveyResponses()
  // }, [mutateSurveyResponses])

  const handleBack = () => {
    navigate("/surveys")
  }

  const columns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
      },
      {
        Header: "USER FULL NAME",
        accessor: "userFullName",
      },
      {
        Header: "SURVEY TITLE",
        accessor: "surveyTitle"
      },
      {
        Header: "DATE ANSWERED",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleString(undefined, {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
          return formattedDate
        },
      },
      // {
      //   Header: "ANSWER/OPTION(S)",
      //   accessor: row => {
      //     if (row.text) return row.text
      //     if (row.optionIds) {
      //       return row.optionIds
      //         .map(optionId => optionIdToNameMap[optionId] || optionId)
      //         .join(", ")
      //     }
      //     return ""
      //   },
      //   Cell: ({ value }) => <p>{value}</p>,
      // },
    ],
    [optionIdToNameMap]);

  const rcolumns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
      },
      // {
      //   Header: "SURVEY ID",
      //   accessor: "surveyId",
      // },
      {
        Header: "RESPONDENT",
        accessor: "userFullName"
      },
      {
        Header: "DATE ANSWERED",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleString(undefined, {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
          return formattedDate
        },
      },
      {
        Header: "ANSWER/OPTION(S)",
        accessor: row => {
          if (row.text) return row.text
          if (row.optionIds) {
            return row.optionIds
              .map(optionId => optionIdToNameMap[optionId] || optionId)
              .join(", ")
          }
          return ""
        },
        Cell: ({ value }) => <p>{value}</p>,
      },
    ],
    [optionIdToNameMap]);
  const qcolumns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
      },
      {
        Header: "QUESTION",
        accessor: "text",
      },
      {
        Header: "TYPE",
        accessor: "type"
      },
      // {
      //   Header: "ANSWER/OPTION(S)",
      //   accessor: row => {
      //     if (row.text) return row.text
      //     if (row.optionIds) {
      //       return row.optionIds
      //         .map(optionId => optionIdToNameMap[optionId] || optionId)
      //         .join(", ")
      //     }
      //     return ""
      //   },
      //   Cell: ({ value }) => <p>{value}</p>,
      // },
    ],
    [optionIdToNameMap]);

  const handleRunSurvey = async () => {
    const updatedSurveyData = {
      ...unformattedSurveyData,
      status: "Running",
    }

    try {
      const response = await updateSurvey(id, updatedSurveyData)
      if (response.status === 200) {
        setUnformattedSurveyData(response.data)
        const formattedData = formatDates(response.data)
        setSurveyData(formattedData)
      }
    } catch (error) {
      console.error("Error updating survey:", error)
      alert("Error updating survey. Please try again")

    }
  }

  const handleCloseSurvey = async () => {
    const updatedSurveyData = {
      ...unformattedSurveyData,
      status: "Closed",
    }

    try {
      const response = await updateSurvey(id, updatedSurveyData)
      if (response.status === 200) {
        setUnformattedSurveyData(response.data)
        const formattedData = formatDates(response.data)
        setSurveyData(formattedData)
      }
    } catch (error) {
      console.error("Error updating survey:", error)
      alert("Error closing survey. Please try again")
    }
  }

  const aliases = {
    id: "ID",
    title: "Title",
    description: "Description",
    createdAt: "Date Created",
    status: "Status",
    // type: "Type",
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Breadcrumbs title="Survey" breadcrumbItem="Survey Details" />
          <Button type="button" color="primary" onClick={handleBack}>
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </div>
        <Row className="justify-content-center">
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end mb-3">
                  {isAdmin || isSuperAdmin ? (
                    <Dropdown
                      isOpen={settingsMenu}
                      toggle={() => setSettingsMenu(!settingsMenu)}
                    >
                      <DropdownToggle color="primary" caret>
                        Actions
                      </DropdownToggle>
                      <DropdownMenu>
                        {surveyData.status !== "Running" && (
                          <DropdownItem onClick={handleRunSurvey}>
                            Run Survey
                          </DropdownItem>
                        )}
                        {surveyData.status !== "Closed" && (
                          <DropdownItem onClick={handleCloseSurvey}>
                            Close Survey
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </div>
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody className="mt-4">
                    {survey &&
                      Object.entries(survey)
                        .filter(
                          ([key]) =>
                            key !== "deleted" &&
                            key !== "options" &&
                            key !== "type" &&
                            key !== "questions" &&
                            key !== "responses"
                        )
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td>
                              <strong>{aliases[key] || key}</strong>
                            </td>
                            <td>
                              {typeof value === "object"
                                ? JSON.stringify(value)
                                : value}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
                <div className="mt-5">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className="active"
                        onClick={() => setActiveTab('1')}
                      >
                        SURVEY QUESTIONS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className=""
                        onClick={() => setActiveTab('2')}
                      >
                        SURVEY RESPONSES
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Card>
                        <CardBody>
                          <CardTitle tag="h5">Survey Questions</CardTitle>
                          <TableContainer
                            columns={qcolumns}
                            data={surveyQuestions || []}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            customPageSize={10}
                            isPagination={true}
                            iscustomPageSizeOptions={true}
                            tableClass="align-middle table-nowrap table-check table"
                            theadClass="table-light"
                            paginationDiv="col-12"
                            pagination="justify-content-left pagination pagination-rounded"
                          />
                        </CardBody>
                      </Card>
                    </TabPane>
                    <TabPane tabId="2">
                      <Card>
                        <CardBody>
                          <CardTitle tag="h5">Survey Responses</CardTitle>
                          <TableContainer
                            columns={rcolumns}
                            data={surveyResponseData || []}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            customPageSize={10}
                            isPagination={true}
                            iscustomPageSizeOptions={true}
                            tableClass="align-middle table-nowrap table-check table"
                            theadClass="table-light"
                            paginationDiv="col-12"
                            pagination="justify-content-left pagination pagination-rounded"
                          />
                        </CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
                </div>


                {/* {surveyData.type === "Open" && (
                  <CardText className="mt-3">
                    <strong>Number of Respondents:</strong>{" "}
                    {openSurveyRespondents || "No respondents available"}
                  </CardText>
                )}

                {surveyData.type !== "Open" && (
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Survey Option</th>
                        <th>Number of Respondents</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(optionCounts).map(([option, count]) => (
                        <tr key={option}>
                          <td>{option}</td>
                          <td>{count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )} */}
              </CardBody>
            </Card>
          </Col>
          {isAdmin || isSuperAdmin || isAnalyst &&



            (

              <Col lg="12" className="mt-4">
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Survey Responses</CardTitle>
                    <TableContainer
                      columns={columns}
                      data={surveyResponseData || []}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                      isPagination={true}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check table"
                      theadClass="table-light"
                      paginationDiv="col-12"
                      pagination="justify-content-left pagination pagination-rounded"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
        </Row>
      </Container>
    </div>
  )
}

export default SurveyDetails
